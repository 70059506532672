import { writable } from 'svelte/store';
import { SESSION_EXPIRE_MS } from './constants.js';

export const notification = writable({
	text: '',
	type: ''
});

export const data = writable({});
export const showSpinner = writable(false);
export const showUpgradePreferredModal = writable(null);
export const mergeAccounts = writable(null);
//is a remote authorization client
export const isRemoteAuthClient = writable(false);

export const mastodonData = writable({});
export const mastodonPreviewData = writable({});

export const twitterData = writable({});
export const githubData = writable({});

//prompt - follow query param
export const followMastodonAccount = writable({});

const createCountdown = () => {
	const { subscribe, set, update } = writable(SESSION_EXPIRE_MS / 1000);
	let interval;
	return {
		subscribe,
		_reset: function () {
			set(SESSION_EXPIRE_MS / 1000);
		},
		start: function () {
			this._reset();
			if (interval) clearInterval(interval);
			interval = setInterval(() => {
				update((n) => n - 1);
			}, 1000);
		},
		stop: function () {
			this._reset();
			clearInterval(interval);
		}
	};
};

export const countdown = createCountdown();
